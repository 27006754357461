import React from 'react';
import './Body.css'; // Import CSS file for styling

const Body = () => {
  return (
    <div className="body-container">
      <div className="content">
        <h1>On the first day of my new administration<br />We will throw out all L2s and replace it<br />with BLAST!</h1>
        <h2>- PACMAN</h2>
        <a href="https://launcher.tornadoblast.bot/token/0x9Bd859f519127f10Fcf3470CF917B03EA172f38F" target='_blank'><button>BUY NOW</button></a>
      </div>
    </div>
  );
}

export default Body;
