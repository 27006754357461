import React from 'react';
import './Navbar.css'; // Import CSS file for styling
import img from './Screenshot 2024-06-25 123259.png';
import img1 from './x-2.png';
import img2 from './tele2.png';
import img3 from './dex2.png';
import img4 from './dext2.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={img} alt="Logo" />
      </div>
      <div className="navbar-social-icons">
        <a href="https://x.com/pacmanomics" target='_blank'><img src={img1} alt="Social Icon 1" /></a>
        <a href="https://t.me/pacmanomics" target='_blank'><img src={img2} alt="Social Icon 2" /></a>
        {/* <a href="#"><img src={img3} alt="Social Icon 3" /></a> */}
        <a href="https://www.dextools.io/app/en/blast/pair-explorer/0x596e65e591f871851e3486b46f0977996ad9b94f?t=1719301645959" target='_blank'><img src={img4} alt="Social Icon 4" /></a>
      </div>
    </nav>
  );
}

export default Navbar;
